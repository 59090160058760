var doit;
window.onresize = function () {
  clearTimeout(doit);
  doit = setTimeout(resizedw, 10);
};

export default function initTabs() {
  document.querySelectorAll('a.refresh').forEach(lnk => {
    lnk.click();
  });
  if (!document.querySelector('.tabs')) return;
  var tabs = document.querySelectorAll('li[data-href]');
  var href0 = tabs[0].dataset.href;
  tabs.forEach(tab => {
    tab.addEventListener('click', _ => activateTab(tab.dataset.href));
  });
  if (href0) {
    activateTab(href0);
  }

  resizedw();
}

function activateTab(href) {
  document.querySelectorAll('[data-href]').forEach(tab => {
    tab.classList.remove('is-active');
  });
  document.querySelectorAll(`[data-href='${href}']`).forEach(tab => {
    tab.classList.add('is-active');
  });
}

function resizedw() {
  var tab = document.querySelector('.tabs.sticky');
  var lists = document.querySelectorAll('.list-top.sticky, .tab_contents');
  if (tab) {
    lists.forEach(list => {
      list.style.top = 83 + tab.offsetHeight - 42 + 'px';
    });
  }
}

import selectInit from './Select2';
import { modalUnload } from './PreventExitUnSaved';
import $ from 'jquery';

export default function initForm() {
  var fileInputs = document.querySelectorAll('input[type="file"]');
  var datetime = document.querySelectorAll('.datetime select');
  var selectrs = document.querySelectorAll('.selectr');
  var modals = document.querySelectorAll('.modal');
  var textareas = document.querySelectorAll('.textarea');
  var lockErrors = document.querySelectorAll('[data-stale-value]');
  var destroyInputs = document.querySelectorAll('input[type="checkbox"][name*="_destroy"]');

  datetime && dateTime(datetime);
  fileInputs && fileUpload(fileInputs);
  selectrs && selectInit(selectrs);
  modals && initCloseModalListeners();
  textareas && initTextareasAutoExpand();
  lockErrors && initLockErrors(lockErrors);
  destroyInputs && initDestroyNested(destroyInputs);
}

function initLockErrors(errors) {
  errors.forEach(e => {
    const control = e.closest('.field').querySelector('.control');
    const input = control.querySelector('input:not([type=hidden]):not(.datetimepicker-dummy-input), textarea, select');
    if (input.tagName === 'SELECT' && !input.classList.contains('select-tags')) {
      const sel = e.dataset.staleValue.split(', ');
      const txt = [...input.options].filter(el => sel.includes(el.value)).map(el => el.text).join(', ');
      e.text = e.text.replace(`[${e.dataset.staleValue}]`, `[${txt}]`);
    }
    e.addEventListener('click', function () {
      if (input.type === 'checkbox') {
        input.checked = e.dataset.staleValue === 'true';
      } else if (input.tagName === 'SELECT') {
        if ($(input).val() instanceof Array) {
          const v = e.dataset.staleValue.split(', ');
          v.forEach(data => { addOption(input, data); });
          $(input).val(v).trigger('change');
        } else {
          addOption(input, e.dataset.staleValue);
          $(input).val(e.dataset.staleValue).trigger('change');
        }
      } else if (input.type === 'text' && input.classList.contains('is-hidden')) {
        if (e.dataset.staleValue === '') {
          input.calendar.clear();
        } else {
          input.calendar.value(e.dataset.staleValue);
          input.calendar.save();
        }
      } else {
        input.value = e.dataset.staleValue;
      }
    });
  });
}

function addOption(select, data) {
  if (data !== '' &&
      select.classList.contains('select-tags') &&
      $(select).find("option[value='" + data + "']").length === 0) {
    var newOption = new Option(data, data, true, true);
    $(select).append(newOption);
  }
}

function initTextareasAutoExpand() {
  document.addEventListener('input', function (event) {
    if (event.target.tagName.toLowerCase() !== 'textarea' ||
        !(event.target.classList.contains('autoExpand'))) return;
    autoExpand(event.target);
  }, false);

  var autoExpand = function (field) {
    field.style.height = 'inherit';
    var computed = window.getComputedStyle(field);
    var height = parseInt(computed.getPropertyValue('border-top-width'), 10) +
                 parseInt(computed.getPropertyValue('padding-top'), 10) +
                 field.scrollHeight +
                 parseInt(computed.getPropertyValue('padding-bottom'), 10) +
                 parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    if (height > 400) height = 400;
    field.style.height = height + 'px';
  };

  for (const ta of document.querySelectorAll('textarea')) {
    if (ta.tagName.toLowerCase() === 'textarea') autoExpand(ta);
  }
}

function initCloseModalListeners() {
  for (const btn of document.querySelectorAll(
    '.modal.is-active .close-modal'
  )) {
    btn.addEventListener('click', e => {
      if (btn.classList.contains('is-cancel') || modalUnload(e)) {
        e.target.closest('.modal.is-active').remove();
      }
    });
  }
}

function dateTime(datetime) {
  datetime.forEach(x => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('select');
    x.before(wrapper);
    wrapper.append(x);
  });
}

function fileUpload(fileInputs) {
  fileInputs.forEach(fileInput => {
    const preview = fileInput.closest('.field').querySelector('.file-preview');

    // const addRemoveButton = img => {
    //   const wrap = document.createElement('span');
    //   wrap.classList.add('image-wrap');
    //   wrap.append(img);
    //   const remove = document.createElement('a');
    //   remove.classList.add('file-remove');
    //   remove.innerHTML = 'x';
    //   remove.addEventListener('click', function(event) {
    //     // document.querySelector('input[type="file"]').value = '';
    //     event.target.parentNode.remove();
    //   });
    //   wrap.append(remove);
    //   preview.append(wrap);
    // };

    // preview.querySelectorAll('img').forEach(img => addRemoveButton(img));

    fileInput.addEventListener('change', event => {
      const files = event.target.files;
      if (files.length > 0) {
        preview.innerHTML = '';
        Array.from(files).forEach(f => {
          var reader = new FileReader();
          reader.onload = file => {
            var img = new Image();
            img.src = file.target.result;
            preview.append(img);

            // addRemoveButton(img)
          };

          reader.readAsDataURL(f);
        });

        if (fileInput.getAttribute('multiple') === null) {
          var img = fileInput.closest('.box').querySelector('.image-edit');
          if (img && !img.querySelector('input').disabled) {
            toggleDelete(img);
          }
        }
      }
    });
  });

  var toggleDelete = img => {
    var field = img.querySelector('input');
    field.disabled = !field.disabled;
    img.classList.toggle('image-delete');
  };

  const imgs = document.querySelectorAll('.image-edit');
  imgs.forEach(img => {
    img.querySelector('a.delete').addEventListener('click', event => {
      toggleDelete(img);
    });
  });
}

export function initDestroyNested(destroyInputs) {
  var markRow = function (checkInput) {
    if (checkInput.checked) {
      checkInput.closest('.row').classList.add('marked-destroy');
    } else {
      checkInput.closest('.row').classList.remove('marked-destroy');
    }
  };
  destroyInputs.forEach(destroyInput => {
    destroyInput.addEventListener('change', event => {
      markRow(event.target);
    });
    markRow(destroyInput);
  });
}

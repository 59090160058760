export default function WorkingHours() {
  const whel = document.getElementById('working-hours-set');

  if (whel !== null) {
    setupSlotButtonsAdd(whel);
    setupSlotButtonsRm(whel);
    setupDropdowns(whel);
    setupInputs(whel);
    validateAndUpdate();
  }
}

function insertSlot(daySet) {
  const template = document.getElementById('ws-template').innerHTML;
  const slots = daySet.getElementsByClassName('wh-slot');
  if (slots.length > 0) {
    slots[slots.length - 1].insertAdjacentHTML('afterend', template);
  } else {
    daySet.querySelector('.wh-day-slots').insertAdjacentHTML('afterbegin', template);
  }

  const newSlot = slots[slots.length - 1];
  setupSlotButtonsRm(newSlot);
  setupInputs(newSlot);
}

function dateRangeOverlaps(a, b) {
  if (a.start <= b.start && b.start <= a.end) return true; // b starts in a
  if (a.start <= b.end && b.end <= a.end) return true; // b ends in a
  if (b.start < a.start && a.end < b.end) return true; // a in b
  return false;
}

function multipleDateRangeOverlaps(ranges) {
  const result = [];
  let i;
  let j;
  for (i = 0; i < ranges.length - 1; i++) {
    for (j = i + 1; j < ranges.length; j++) {
      if (dateRangeOverlaps(ranges[i], ranges[j])) result.push([ranges[i], ranges[j]]);
    }
  }

  return result;
}

function getValidDay(day) {
  if (day.querySelector('.wd-o').value === 'custom') {
    let ranges = [];
    for (const slot of day.getElementsByClassName('wh-slot')) {
      const timeFrom = slot.querySelector('.time-from');
      const timeTo = slot.querySelector('.time-to');
      slot.classList.remove('has-background-warning', 'has-background-primary',
        'has-background-danger');
      if (timeFrom.checkValidity() && timeTo.checkValidity()) {
        if (timeFrom.value < timeTo.value) {
          ranges.push({ start: timeFrom.value, end: timeTo.value, slot: slot });
        } else slot.classList.add('has-background-danger');
      }
    }

    for (const overlap of multipleDateRangeOverlaps(ranges)) {
      overlap[0].slot.classList.add('has-background-primary');
      overlap[1].slot.classList.add('has-background-warning');
      ranges = ranges.filter((range) => range.slot !== overlap[1].slot);
    }

    if (ranges.length > 0) return ranges.map(r => r.start + '-' + r.end).sort().join(',');
  }

  return '-';
}

function validateAndUpdate() {
  const daysEls = document.getElementsByClassName('work-day-set');
  const sameinweekEl = document.getElementById('sameinweek');
  const whValues = [];
  if (sameinweekEl.checked) {
    document.getElementById('mo-to-fri').classList.remove('invisible');
  } else {
    document.getElementById('mo-to-fri').classList.add('invisible');
  }

  for (let i = 0; i < daysEls.length; i++) {
    const day = daysEls[i];
    if (i > 0 && i < 5 && sameinweekEl.checked) {
      whValues.push(whValues[0]);
      day.classList.add('invisible');
    } else {
      day.classList.remove('invisible');
      whValues.push(getValidDay(day));
    }
  }

  document.getElementById('vet_clinic_working_hours').value = whValues.join(';');
}

function setupSlotButtonsAdd(containerEl) {
  for (const button of containerEl.querySelectorAll('.wh-slot-add')) {
    button.addEventListener('click', function (event) {
      insertSlot(event.target.closest('.work-day-set'));
    });
  }
}

function setupSlotButtonsRm(containerEl) {
  for (const button of containerEl.querySelectorAll('.wh-slot-rm')) {
    button.addEventListener('click', function (event) {
      event.target.closest('.wh-slot').remove();
      validateAndUpdate();
    });
  }
}

function setupDropdowns(containerEl) {
  for (const select of containerEl.querySelectorAll('.wd-o')) {
    select.addEventListener('change', (event) => {
      const daysetel = event.target.closest('.work-day-set');
      const slots = daysetel.querySelector('.wh-day-slots');
      const inputs = slots.querySelectorAll('input');
      const workDay = event.target.value === 'custom';
      if (workDay) {
        slots.classList.remove('invisible');
        if (inputs.length === 0) insertSlot(daysetel);
      } else {
        slots.classList.add('invisible');
      }

      for (const input of inputs) input.required = workDay;
      validateAndUpdate();
    });
  }
}

function setupInputs(containerEl) {
  for (const input of containerEl.querySelectorAll('input')) {
    input.addEventListener('change', function (event) {
      validateAndUpdate();
    });
  }
}

import Chart from 'chart.js';

export default function initWeightChart() {
  const charts = document.querySelectorAll('canvas.weightChart');

  const colors = [[57, 106, 177], [210, 124, 48], [62, 160, 81], [204, 37, 41]];
  const getColor = function (i) {
    if (i >= colors.length) i = i % colors.length;
    return colors[i][0] + ', ' + colors[i][1] + ', ' + colors[i][2];
  };

  charts.forEach((chart, key) => {
    var ctx = chart.getContext('2d');
    var values = JSON.parse(chart.dataset.values);
    var mychart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: values.map((v, i) => {
          return {
            ...v,
            ...{
              backgroundColor: 'rgba(' + getColor(i) + ', 0.2)',
              borderColor: 'rgba(' + getColor(i) + ', 1)',
              showLine: true,
              pointRadius: 5,
              pointBackgroundColor: 'rgba(' + getColor(i) + ', 1)',
              data: v.data.map(e => {
                return { x: new Date(e.x), y: e.y };
              }),
              yAxisID: 'y-axis-' + i,
            },
          };
        }),
      },
      options: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                tooltipFormat: 'DD/MM/YYYY',
              },
            },
          ],
          yAxes: values.map((v, i) => {
            return {
              scaleLabel: {
                display: true,
                labelString: v.ylabel,
              },
              position: (i % 2 === 0 ? 'left' : 'right'),
              ticks: {
                beginAtZero: true,
              },
              id: 'y-axis-' + i,
            };
          }),

        },
      },
    });

    chart.onclick = function (evt) {
      var activePoints = mychart.getElementsAtEvent(evt);

      console.log('activePoints=', activePoints);
      // use _datasetIndex and _index from each element of the activePoints array
    };
  });
}

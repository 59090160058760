// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'stylesheets/application.scss';

import initNotification from './modules/Notification';
import SiteHeader from './modules/SiteHeader';
import initForm from './modules/Form';
import RangeSlider from './modules/RangeSlider';
import initFilters from './modules/Filters';
import initTabs from './modules/Tabs';
import Calendar from './modules/Calendar';
import TimeTable from './modules/TimeTable';
import initEvent from './modules/Event';
import initDropdown from './modules/Dropdown';
import WorkingHours from './modules/WorkingHours';
import preventExitUnSaved from './modules/PreventExitUnSaved';
import Exam from './modules/Exam';
import initWeightChart from './modules/WeightChart';
import Nest from './modules/Nest';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('chart.js');

document.addEventListener('turbolinks:load', function () {
  document.querySelector('body').classList.remove('nojs');
  if (document.location.hash === '#demo') {
    document
      .querySelector('input#user_email')
      .setAttribute('value', 'demo@ron.gr');
    document
      .querySelector('input#user_password')
      .setAttribute('value', 'demoron');
  }

  SiteHeader();

  initNotification('.notification');
  initForm();
  RangeSlider();
  initFilters();
  initTabs();
  preventExitUnSaved();
  Calendar();
  TimeTable();
  initEvent();
  initDropdown();
  WorkingHours();
  Exam();
  initWeightChart();
  Nest();
});

document.addEventListener('ajax:success', () => {
  initForm();
  initFilters();
  preventExitUnSaved();

  initNotification('.notification');
  Calendar();
  TimeTable();
  Exam();
  Nest();
});

document.addEventListener('ajax:before', () => {
  var times = document.querySelectorAll('input[name=timestamp]');
  times.forEach(time => {
    time.value = Date.now();
  });
});

document.addEventListener('initnotifs', (event, data, status, xhr) => {
  initNotification('.notification');
  Exam();
});

document.addEventListener('initforms', (event, data, status, xhr) => {
  initForm();
  Calendar();
  Exam();
  Nest();
});

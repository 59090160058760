import { initDestroyNested } from './Form';

export default function Exams() {
  const exams = document.querySelectorAll('.exam-form');
  exams.forEach(exam => { initExam(exam); });
  const rangeExams = document.querySelectorAll('.exam-form, .columns.exams');
  rangeExams.forEach(exam => { initRanges(exam); });
}

function initExam(exam) {
  exam.isDirty = false;
  filterLib(exam);
  const addExamBtn = exam.querySelector('.exam-add');
  if (addExamBtn && addExamBtn.hasBeenInited !== true) {
    addExamBtn.hasBeenInited = true;
    addExamBtn.addEventListener('click', () => { addExam(exam); });
  }

  const addExamGroupBtn = exam.querySelector('.exam-group-add');
  if (addExamGroupBtn && addExamGroupBtn.hasBeenInited !== true) {
    addExamGroupBtn.hasBeenInited = true;
    addExamGroupBtn.addEventListener('click', () => { addExamGroup(exam); });
  }

  const labSelect = exam.querySelector('.exam-lab');
  if (labSelect && labSelect.hasBeenInited !== true) {
    labSelect.hasBeenInited = true;
    labSelect.addEventListener('change', () => { initRanges(exam); });
  }

  const rows = exam.querySelectorAll('.row');
  rows.forEach(row => { initRow(exam, row); });
}

function initRow(exam, row) {
  const btn = row.querySelector('.exam-delete');
  if (btn && btn.hasBeenInited !== true) {
    btn.hasBeenInited = true;
    btn.addEventListener('click', () => { delExam(row, exam); });
  }

  const value = row.querySelector('.exam-value');
  if (row.lib && value && value.hasBeenInited !== true) {
    value.hasBeenInited = true;
    value.addEventListener('input', () => { colorRow(row); });
  }
}

function initRanges(exam) {
  const sl = exam.querySelector('.exam-lib');
  const lab = exam.querySelector('.exam-lab');
  exam.lab = lab ? lab.value || 0 : 0;
  if (!sl) return;

  exam.libOptions = document.createElement('SELECT');
  exam.libOptions.innerHTML = sl.getAttribute('originalOptions');
  const rows = exam.querySelectorAll('.row');
  rows.forEach(row => { initRange(exam, row); });
}

function initRange(exam, row) {
  const rangeMin = row.querySelector('.exam-range-min');
  const rangeMax = row.querySelector('.exam-range-max');
  if (!rangeMax || !rangeMin) return;

  const examLibId = row.querySelector('.lib-id').value;
  const lib = exam.libOptions.querySelector(`option[value="${examLibId}"]`);
  row.lib = lib;
  row.lab = exam.lab;
  rangeMax.innerText = lib.dataset[`max-${exam.lab}`];
  rangeMin.innerText = lib.dataset[`min-${exam.lab}`];
  colorRow(row);
}

function colorRow(row) {
  const value = row.querySelector('.exam-value');
  const v = parseFloat(value.value);
  const max = parseFloat(row.lib.dataset[`max-${row.lab}`]);
  const min = parseFloat(row.lib.dataset[`min-${row.lab}`]);
  const icon = row.querySelector('.icon i');

  icon.classList.remove(
    'fa-check',
    'fa-exclamation-triangle',
    'fa-arrow-circle-down',
    'fa-arrow-circle-up'
  );

  var color = 'black';
  var aclass = 'fa-exclamation-triangle';
  if (max && min && v) {
    if (v <= max && v >= min) {
      color = 'green';
      aclass = 'fa-check';
    } else {
      color = 'red';
      if (v < min) aclass = 'fa-arrow-circle-down';
      if (v > max) aclass = 'fa-arrow-circle-up';
    }
  }

  icon.classList.add(aclass);
  icon.style.color = color;
}

function delExam(e, exam) {
  if (e.isNew !== true) { exam.isDirty = true; }

  e.parentNode.removeChild(e);
  filterLib(exam);
}

function addExam(exam, lib = null) {
  const examLib = exam.querySelector('.exam-lib');
  const val = lib || examLib.options[examLib.selectedIndex];
  const template = exam.querySelector('.exam-template');
  if (val.value) {
    const id = exam.querySelectorAll('div.row').length;
    const html = template.innerHTML
      .replaceAll('{{lib_unit}}', val.dataset.unit)
      .replaceAll('%7B%7Blib_id%7D%7D', val.value)
      .replaceAll('{{lib_id}}', val.value)
      .replaceAll('{{lib_name}}', val.text)
      .replaceAll('{{DELETE}}', id)
      .replaceAll('__DELETE__', id);
    const entries = exam.querySelector('.exam-entries');
    entries.insertAdjacentHTML('beforeend', html);
    const row = entries.querySelector('div.row:last-child');
    const deleteCheckBox = row.querySelectorAll(`input[type="checkbox"][name*="_destroy"][id*="${id}"]`);
    initDestroyNested(deleteCheckBox);
    row.isNew = true;
    initRange(exam, row);
    initRow(exam, row);
    filterLib(exam);
  } else { alert(template.dataset.empty); }
}

function addExamGroup(exam) {
  const examLib = exam.querySelector('.exam-lib');
  const examLibGroup = exam.querySelector('.exam-lib-group');
  if (!examLib || !examLibGroup) return;

  [...examLib.options].forEach(lib => {
    if (lib.dataset.lib_group === examLibGroup.value) {
      addExam(exam, lib);
    }
  });
}

function filterLib(exam) {
  const sl = exam.querySelector('.exam-lib');
  const idx = sl.selectedIndex;
  const val = sl.value;
  const libIds = [...exam.querySelectorAll('.lib-id')].map(s => (s.value));
  sl.innerHTML = sl.getAttribute('originalOptions');
  libIds.forEach(id => {
    const option = sl.querySelector(`option[value="${id}"]`);
    if (option) option.remove();
  });
  if (sl.querySelector(`option[value="${val}"]`) === null) {
    var opt = [...sl.options].map((o, i) => ([o, i]));
    if (sl.masterProp && sl.masterID && sl.masterID !== '') {
      opt = opt.filter(o => (o[0].dataset[sl.masterProp] === sl.masterID));
    }
    var idx2 = opt.findIndex(e => e[1] >= idx);
    if (idx2 === -1) idx2 = opt.length - 1;
    sl.selectedIndex = (idx2 === -1) ? 0 : opt[idx2][1];
  } else {
    sl.value = val;
  }
}

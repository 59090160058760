
export default function initDropdown() {
  const mainmenu = document.getElementById('main-menu');
  if (mainmenu) {
    if (mainmenu.classList.contains('is-hoverable')) {
      mainmenu.classList.remove('is-hoverable');
    }
  }

  const dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)');

  if (dropdowns.length > 0) {
    dropdowns.forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.stopPropagation();
        el.classList.toggle('is-active');
      });

      el.addEventListener('blur', function (e) {
        el.classList.remove('is-active');
      });
    });

    document.addEventListener('click', function (e) {
      closeDropdowns();
    });
  }

  const closeDropdowns = () => {
    dropdowns.forEach(function (el) {
      el.classList.remove('is-active');
    });
  };

  const navTo = (url) => {
    if (url.length > 0) {
      console.log('navigating to', url);
      window.location.href = url;
    }
  };

  document.addEventListener('keydown', function (event) {
    const e = event || window.event;
    let a = false;
    if (e.key === 'Esc' || e.key === 'Escape') {
      closeDropdowns();
    } else if (e.ctrlKey && e.key === '1') {
      a = document.getElementById('main-nav').children[0];
    } else if (e.ctrlKey && e.key === '2') {
      a = document.getElementById('main-nav').children[1];
    } else if (e.ctrlKey && e.key === '3') {
      a = document.getElementById('main-nav').children[2];
    } else if (e.ctrlKey && e.key === '4') {
      a = document.getElementById('main-nav').children[3];
    } else if (e.ctrlKey && e.key === '`') {
      mainmenu.classList.toggle('is-active');
      mainmenu.children[0].getElementsByClassName('button')[0].focus();
    }

    if (typeof (a) !== 'undefined' && a !== false) {
      navTo(a.href);
    }
  });
}

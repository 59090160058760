function SiteHeader() {
  const themeChanger = document.querySelector('header .theme-changer');
  themeChanger.addEventListener('click', function () {
    document.body.classList.toggle('inverted');
    document.cookie =
      'theme= ' +
      (document.body.classList.contains('inverted') ? 'inverted' : '');
  });
}

export default SiteHeader;

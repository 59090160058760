import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import tr from '../../translations.json';

function RangeSlider() {
  const slider = document.getElementById('steps-slider');
  const input0 = document.getElementById('min_age');
  const input1 = document.getElementById('max_age');
  const inputs = [input0, input1];

  if (!slider) return;
  const form = slider.closest('.filters-form');

  input0.classList.add('hidden');
  input1.classList.add('hidden');

  slider.noUiSlider && slider.noUiSlider.destroy();
  slider.innerHTML = '';

  const t = tr[document.querySelector('html').getAttribute('lang') || 'en'];

  const ageText = value => {
    const month = value === 1 ? t.month : t.months;
    const year = value === 13 ? t.year : t.years;

    return value < 13
      ? `${Math.floor(value)} ${month}`
      : `${value / 12} ${year}`;
  };

  const fullText = ([v0, v1]) => {
    const minAge = ageText(v0);
    const maxAge = ageText(v1);

    let out = `${minAge} - ${maxAge}`;

    if (v0 === 0) {
      out = `${t.to} ${maxAge}`;
    }

    if (v1 === 1200) {
      out = `${t.from} ${minAge}`;
    }

    if (v0 === 0 && v1 === 1200) {
      out = `${t.ages}`;
    }

    form.querySelector('small').innerHTML = out;
  };

  const formatter = {
    to: value => ageText(value),
    from: value => ageText(value),
  };

  noUiSlider.create(slider, {
    start: [0, 1200],
    connect: true,
    step: 1,

    // tooltips: [true, wNumb({decimals: 0})],
    tooltips: [formatter, formatter],
    format: wNumb({ decimals: 0 }),
    range: {
      min: [0],
      '50%': [12, 12],
      '60%': [60, 60],
      '75%': [120, 120],
      '90%': [240, 240],
      '99%': [600, 600],
      max: 1200,
    },
  });

  fullText([0, 1200]);

  slider.noUiSlider.on('change', function (values, handle) {
    inputs[handle].value = values[handle];
    fullText(values);
    Rails.fire(form, 'submit');
  });
}

export default RangeSlider;

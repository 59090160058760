const formPrefix = 'form:not([method="GET"]) ';

function inputOrTextAreaHasChanged() {
  for (const control of document.querySelectorAll(
    ['input', 'textarea'].map(e => formPrefix + e).join(', ')
  )) {
    if (
      control.classList.contains('datetimepicker-dummy-input') ||
      control.parentElement.classList.contains('timepicker-input')
    ) {
      continue; // These inputs are changed by bulma calendar
    }

    if (control.id === 'user_current_password') {
      continue; // Otherwise the user edit page issues warning when nothing has changed
    }

    if (control.value !== control.defaultValue) {
      console.log(
        'Form value changed',
        control,
        control.value,
        control.defaultValue
      );
      return true;
    }
  }

  return false;
}

function checkboxHasChanged() {
  for (const control of document.querySelectorAll(
    formPrefix + "input[type='checkbox']"
  )) {
    if (
      control.checked !== control.defaultChecked &&
      !control.classList.contains('dirtyharry')
    ) {
      console.log(
        'Form value changed',
        control,
        control.checked,
        control.defaultChecked
      );
      return true;
    }
  }

  return false;
}

function selectHasChanged() {
  for (const control of document.querySelectorAll(
    formPrefix + 'option:not(:first-child)'
  )) {
    if (control.selected !== control.defaultSelected) {
      console.log(
        'Form value changed',
        control,
        control.selected,
        control.defaultSelected
      );
      return true;
    }
  }

  return false;
}

function examHasChanged() {
  for (const exam of document.querySelectorAll('.exam-form')) {
    if (exam.isDirty) return true;
    for (const row of exam.querySelectorAll('.row')) {
      if (row.isNew === true) return true;
    }
  }

  return false;
}

function formHasChanged(e) {
  // console.log(
  //   inputOrTextAreaHasChanged(),
  //   selectHasChanged(),
  //   checkboxHasChanged()
  // );

  return (
    inputOrTextAreaHasChanged() ||
    selectHasChanged() ||
    checkboxHasChanged() ||
    examHasChanged()
  );
}

function turbolinksUnload(e) {
  // console.log('we are at turbolinks: ', this);
  if (formHasChanged(e)) {
    const res = confirm(
      'This page is asking you to confirm that you want to leave - ' +
        'data you have entered may not be saved.'
    );
    if (res) {
      document.removeEventListener('turbolinks:before-cache', turbolinksUnload);
    } else {
      e.preventDefault();
    }

    return res;
  }
}

export function modalUnload(e) {
  // console.log('we are at modal: ', this);
  if (formHasChanged(e)) {
    return confirm(
      'This page is asking you to confirm that you want to leave - ' +
        'data you have entered may not be saved.'
    );
  }

  return true;
}

function normalUnload(e) {
  // console.log('we are at normal', this);
  if (formHasChanged(e)) {
    e.preventDefault();

    // Chrome requires returnValue to be set
    e.returnValue = '';
  }
}

function removeUnloadListeners() {
  window.removeEventListener('beforeunload', normalUnload);
  document.removeEventListener('turbolinks:before-visit', turbolinksUnload);
}

export default function preventExitUnSaved() {
  window.addEventListener('beforeunload', normalUnload);

  document.addEventListener('turbolinks:before-visit', turbolinksUnload);

  for (const form of document.getElementsByTagName('form')) {
    form.addEventListener('submit', removeUnloadListeners);
    for (const cancelLink of form.querySelectorAll('a.is-cancel')) {
      cancelLink.addEventListener('click', removeUnloadListeners);
    }
  }

  // console.log('Listener Added');
}

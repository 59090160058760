function TimeTable() {
  const tt = document.getElementById('timetable-week');

  if (tt === null) return;

  if (typeof tt.tt_first_run === 'undefined') {
    tt.tt_first_run = true;
  }

  const getFirstDropzone = el => {
    let maxDepth = 5;
    while (maxDepth > 0) {
      if (el.classList && el.classList.contains('dropzone')) {
        return el;
      } else {
        el = el.parentNode;
      }

      maxDepth = maxDepth - 1;
    }

    return false;
  };

  /**
   * @param {String} HTML representing a single element
   * @return {Element}
   */

  // const htmlToElement = html => {
  //   const template = document.createElement('template');
  //   html = html.trim(); // Never return a text node of whitespace as the result
  //   template.innerHTML = html;
  //   return template.content.firstChild;
  // };

  const getFirstEvent = el => {
    let maxDepth = 5;
    while (maxDepth > 0) {
      if (el.classList && el.classList.contains('tt-event')) {
        return el;
      } else {
        el = el.parentNode;
      }

      maxDepth = maxDepth - 1;
    }

    return false;
  };

  const closeEventMenus = () => {
    const elsopen = document.getElementsByClassName('tt-active');
    for (const elo of elsopen) {
      elo.classList.remove('tt-active');
    }
  };

  const setupEventsClickable = () => {
    const eventEls = document.getElementsByClassName('tt-event');
    for (const eventEl of eventEls) {
      eventEl.addEventListener('click', function (event) {
        event.stopPropagation();
        if (event.target.classList.contains('delete')) return;
        const el = getFirstEvent(event.target);

        if (typeof el.classList !== 'undefined') {
          if (!el.classList.contains('tt-active')) {
            closeEventMenus();

            el.classList.add('tt-active');
            const modal = el.querySelector('.event-menu');
            Rails.ajax({
              type: 'GET',
              url: modal.dataset.event_url,
              dataType: 'script',
              success: function (response) {
                // The modal is created from /app/views/events/show.js.rtb
                const closeEls = modal.getElementsByClassName('delete');
                for (const closeEl of closeEls) {
                  closeEl.addEventListener('click', function (event) {
                    const el = getFirstEvent(event.target);
                    if (typeof el.classList !== 'undefined') {
                      if (el.classList.contains('tt-active')) {
                        el.classList.remove('tt-active');
                      }
                    }
                  });
                }
              },

              error: function (response) {
                console.log('ERROR');
              },
            });
          }
        }
      });
    }
  };

  const setupTDsClickable = () => {
    const tdEls = document.getElementsByClassName('dropzone');
    for (const tdEl of tdEls) {
      if (!tdEl.classList.contains('nw')) {
        tdEl.addEventListener('click', function (event) {
          const el = event.target;

          const elsopen = document.getElementsByClassName('tt-active');
          if (elsopen.length > 0) {
            closeEventMenus();
          } else {
            if (el.classList.contains('tt-sel')) {
              const moveToD = document.getElementById('timetable-week').rows[0]
                .cells[
                  el.cellIndex +
                  (el.parentNode.children[0].nodeName === 'TH' ? 0 : 1)
                ].dataset.date;
              const moveToT = el.parentNode.dataset.time;
              const dateTimeNew = moveToD + ' ' + moveToT;

              const newEventLink = document.getElementById('timetable-week')
                .dataset.new_event_href;
              window.location.href =
                newEventLink + '&datetime=' + encodeURIComponent(dateTimeNew);
            } else {
              const elssel = document.getElementsByClassName('tt-sel');
              if (elssel.length > 0) {
                for (const elo of elssel) {
                  elo.classList.remove('tt-sel');
                }
              } else {
                el.classList.add('tt-sel');
              }
            }
          }
        });
      }
    }
  };

  const setupPopupButtons = () => {
    const yesButton = document.getElementById('tt-move-yes');
    yesButton &&
      yesButton.addEventListener('click', function (event) {
        event.preventDefault();
        moveEvent();
      });

    const noButton = document.getElementById('tt-move-no');
    noButton &&
      noButton.addEventListener('click', function (event) {
        document.getElementById('tt-modal').classList.remove('is-active');
      });
  };

  const moveEvent = () => {
    const popel = document.getElementById('tt-modal');
    const dateTimeNew = popel.dataset.move_to_d + ' ' + popel.dataset.move_to_t;

    if (
      Date.parse(dateTimeNew) &&
      (popel.dataset.move_to_d !== popel.dataset.move_from_d ||
        popel.dataset.move_to_t !== popel.dataset.move_from_t)
    ) {
      document.getElementById('tt-move-yes').classList.add('is-loading');
      document.getElementById('tt-move-no').setAttribute('disabled', true);
      Rails.ajax({
        type: 'PATCH',
        url: '/events/' + popel.dataset.move_id + '.js',
        data:
          'event[datetime]=' +
          dateTimeNew +
          '&position=events-index&' +
          window.location.href.split('?')[1],
        success: function (response) {
          // initNotification('.notification');
          setupEventsClickable();
          setupTDsClickable();
          setupPopupButtons();
          document.getElementById('tt-modal').classList.remove('is-active');
          const eventev = new Event('initnotifs');
          document.dispatchEvent(eventev);
        },

        error: function (response) {
          setupEventsClickable();
          setupPopupButtons();
          /* FIXME */
        },
      });
    }
  };

  const updateCurTime = () => {
    const tt = document.getElementById('timetable-week');
    if (tt !== null) {
      const ttcurEls = tt.getElementsByClassName('tt-cur');
      for (const ttcurEl of ttcurEls) {
        ttcurEl.classList.remove('tt-cur');
      }

      const d = new Date();

      const rows = tt.querySelectorAll('tr.hh, tr.mm');
      let dmin = 0;
      for (let i = 0; i < rows.length - 1; i++) {
        if (rows[i].dataset.time.split(':')[1] === '00') {
          dmin =
            parseInt(rows[i + 1].dataset.time.split(':')[1]) -
            parseInt(rows[i].dataset.time.split(':')[1]);
          break;
        }
      }

      if (dmin > 0) {
        let min = '00';
        const blocks = Math.floor(d.getMinutes() / dmin);
        min = blocks * dmin;

        let dateIdx = -1;
        const heads = tt.rows[0].cells;
        for (let i = 0; i < heads.length; i++) {
          if (
            heads[i].dataset.date ===
            d.getFullYear() +
              '-' +
              ('0' + (d.getMonth() + 1).toString()).slice(-2) +
              '-' +
              ('0' + d.getDate()).slice(-2)
          ) {
            dateIdx = i;
          }
        }

        const sel =
          "tr[data-time='" +
          d.getHours() +
          ':' +
          ('0' + min.toString()).slice(-2) +
          "']";
        const c = tt.querySelectorAll(sel);
        if (c.length > 0 && dateIdx >= 0) {
          c[0].cells[dateIdx].classList.add('tt-cur');
        }
      }
    }
  };

  if (tt !== null) {
    setInterval(updateCurTime, 2000);
    setupEventsClickable();
    setupTDsClickable();
    setupPopupButtons();

    if (tt.tt_first_run) {
      /* events fired on the draggable target */
      document.addEventListener('drag', function (event) {}, false);

      document.addEventListener(
        'dragstart',
        function (event) {
          // store a ref. on the dragged elem
          this.dragged = event.target;

          // make it half transparent
          event.target.style.opacity = 0.5;
        },

        false
      );

      document.addEventListener(
        'dragend',
        function (event) {
          // reset the transparency
          event.target.style.opacity = '';
        },

        false
      );

      /* events fired on the drop targets */
      document.addEventListener(
        'dragover',
        function (event) {
          // prevent default to allow drop
          event.preventDefault();
        },

        false
      );

      document.addEventListener(
        'dragenter',
        function (event) {
          const dzaEls = document.getElementsByClassName('tt-dz-active');
          for (const dzaEl of dzaEls) {
            dzaEl.classList.remove('tt-dz-active');
          }

          // highlight potential drop target when the draggable element enters it
          const el = getFirstDropzone(event.target);
          if (el) {
            if (el !== getFirstDropzone(this.dragged)) {
              el.classList.add('tt-dz-active');
            }
          }
        },

        false
      );

      document.addEventListener('dragleave', function (event) {}, false);

      document.addEventListener(
        'drop',
        function (event) {
          // prevent default action (open as link for some elements)
          event.preventDefault();
          const el = getFirstDropzone(event.target);

          // move dragged elem to the selected drop target
          if (el.classList.contains('dropzone')) {
            // el.style.background = "";
            el.classList.remove('tt-dz-active');

            const popel = document.getElementById('tt-modal');

            popel.dataset.move_to_d = document.getElementById(
              'timetable-week'
            ).rows[0].cells[
              el.cellIndex +
                (el.parentNode.children[0].nodeName === 'TH' ? 0 : 1)
            ].dataset.date;

            popel.dataset.move_to_t = el.parentNode.dataset.time;

            popel.dataset.move_from_d = document.getElementById(
              'timetable-week'
            ).rows[0].cells[
              this.dragged.parentNode.cellIndex +
                (this.dragged.parentNode.parentNode.children[0].nodeName ===
                'TH'
                  ? 0
                  : 1)
            ].dataset.date;
            popel.dataset.move_from_t = this.dragged.parentNode.parentNode.dataset.time;
            popel.dataset.move_id = this.dragged.id.replace('event-', '');

            const dtf = new Date(
              Date.parse(
                popel.dataset.move_from_d +
                  'T' +
                  popel.dataset.move_from_t +
                  ':00'
              )
            );
            const dtt = new Date(
              Date.parse(
                popel.dataset.move_to_d + 'T' + popel.dataset.move_to_t + ':00'
              )
            );
            document.getElementsByClassName(
              'tt-event-move-from'
            )[0].innerHTML = dtf.toLocaleString();
            document.getElementsByClassName(
              'tt-event-move-to'
            )[0].innerHTML = dtt.toLocaleString();
            document.getElementsByClassName('tt-event-move-name')[0].innerHTML =
              this.dragged.children[0].outerHTML +
              this.dragged.children[1].innerText;
            if (
              popel.dataset.move_from_t !== popel.dataset.move_to_t ||
              popel.dataset.move_from_d !== popel.dataset.move_to_d
            ) {
              document
                .getElementById('tt-move-yes')
                .classList.remove('is-loading');
              document.getElementById('tt-move-no').removeAttribute('disabled');
              document.getElementById('tt-modal').classList.add('is-active');
            }

            this.dragged = null;
          }
        },

        false
      );
    }
  }

  tt.tt_first_run = false;
}

export default TimeTable;

import $ from 'jquery';

export default function initFilters() {
  const forms = document.querySelectorAll('.filters-form');
  forms.forEach(form => {
    const submits = form.querySelectorAll('[type=submit].is-primary');
    submits.forEach(submit => submit.remove());
    addListener('select', 'change', form);
    addListener('input', 'input', form);
  });
}

function addListener(query, listener, form) {
  form.querySelectorAll(query).forEach(input => {
    if (!input.classList.contains('custom-submit')) {
      if (input.submitListener !== true) {
        input.submitListener = true;
        if (input.classList.contains('selectr')) {
          $(input).on(listener, _ => submitForm(form, input));
        } else {
          input.addEventListener(listener, _ => submitForm(form, input));
        }
      }
    }
  });
}

function submitForm(form, input) {
  if (input.canNotSubmit !== true) {
    Rails.fire(form, 'submit');
  }
}

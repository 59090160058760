import bulmaCalendar from 'bulma-calendar/src/js/index.js';

function Calendar() {
  const locale = document.querySelector('html').getAttribute('lang') || 'en';

  const params = {
    lang: locale,
    dateFormat: 'YYYY-MM-DD',
    weekStart: 1,
    minuteSteps: 5
  };

  document.querySelectorAll('[type="date"], [type="datetime"]').forEach(dateInput => {
    // Hack, ths is needed because the calendar is not showing the correct month otherwize

    params.startDate = dateInput.value ? new Date(dateInput.value) : '';
    const calendar = bulmaCalendar.attach(dateInput, params)[0];
    dateInput.calendar = calendar;
    calendar.datePicker.on('select', elem => {
      calendar.save();
      var form = calendar.element.form;
      if (form.classList.contains('filters-form')) {
        Rails.fire(form, 'submit');
        calendar.refresh();
      }
    });
  });
}

export default Calendar;

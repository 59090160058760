export default function initEvent() {
  var element = document.getElementById('list_to_render');
  if (element) {
    element.addEventListener('change', fixControls);
  }
}

function fixControls(ev) {
  var list = ev.target.value;
  var week = list === 'list_timetable_week';
  var day = list === 'list_timetable_day';
  var all = list === 'list';

  disable('directionevents', week);
  disable('columnevents', week);
  disable('perevents', week);

  disable('date_from', week || day);
  disable('date_to', week || day);
  disable('date_selected', all);

  Rails.fire(ev.target.form, 'submit');
}

function disable(selector, disabled) {
  var input = document.getElementById(selector);
  input.disabled = disabled;
  var classList = input.closest('.control').classList;
  disabled ? classList.add('hidden') : classList.remove('hidden');
}

/* eslint-disable */

// jscs:disable
import $ from 'jquery';
import 'select2';
// import 'select2/dist/css/select2.css';
import './el';

export default function selectInit(selectrs) {
  initProps();
  initSelects(selectrs);
  resetSelects();
}

function initSelects(selectrs) {
  selectrs.forEach(select => {
    initSelect(select);
  });
}

function initProps() {
  document.querySelectorAll('select[data-field-query]').forEach(select => {
    linkSelect(select.dataset.fieldQuery, select.dataset.fieldParam);
  });

  document.querySelectorAll('select[data-field-query-slave]').forEach(select => {
    linkMaster(select, select.dataset.fieldQuerySlave, select.dataset.fieldParamSlave);
  });

  document.querySelectorAll('select[data-slave-data-id]').forEach(select => {
    filterMasterSlave(select, select.dataset.slaveDataId, select.dataset.slaveDataProp);
  });

  document.querySelectorAll('select.color-tag').forEach(select => {
    colorTag(select);
  });
}

function replaceParamValue(link, param, value) {
  var reg = new RegExp("(" + param + "=).*?(&)", "");
  return link.replace(reg, '$1' + value + '$2');
}

function linkMaster(master, slaveID, prop) {
  var link = document.querySelector(slaveID + ' .field-link');
  if (master && link) {
    var masterLink = () => {
      link.href = replaceParamValue(link.href, prop, master.value);
    };
    if (master.masterLink === undefined) {
      master.addEventListener('change', masterLink);
      master.masterLink = masterLink;
      masterLink();
    }
  }
}

function linkSelect(selectID, prop) {
  var select = document.querySelector(selectID + ' select');
  var link = document.querySelector(selectID + ' .field-link');
  if (select && link) {
    var selectLink = () => {
      var val = (select.selectedIndex === 0) ? '' : select[select.selectedIndex].text
      link.href = replaceParamValue(link.href, prop, val);
    };
    if (select.selectLink === undefined) {
      select.addEventListener('change', selectLink);
      select.selectLink = selectLink;
      selectLink();
    }
    var resetSelect = () => {
      const sel = $(select);
      const element = sel.select2('data')[0].element
      select.dataset.reset = element.dataset.select2Tag === 'true';
    }
    if (link.hasReset !== true) {
      link.addEventListener('click', resetSelect);
      link.hasReset = true;
    }
  }
}

function selectHandler(e) {
  var data = e.params.data;
  if (!data.element) { // if a new item is entered
    e.target.closest('.ajax-field').querySelector('.field-link').click();
  }
}

function filterMasterSlave(master, slaveID, prop, options = { }) {
  var slave = document.getElementById(slaveID);

  if (master && slave) {
    var defaultMatcher = $.fn.select2.defaults.defaults.matcher;
    var match = (params, data) => {
      const matchVal = [data.element.dataset[prop], ''].includes(master.value);
      const matchIdx = master.selectedIndex === 0;
      if (matchVal || matchIdx || data.id === '') {
        return defaultMatcher(params, data);
      } else { return null; }
    };
    options.matcher = match;
    slave.hasBeenInited = false;
    initSelect(slave, options);

    var changeSlave = () => {
      const id = master.value;
      const opt = slave.options[slave.selectedIndex];
      slave.masterID = id;
      slave.masterProp = prop;
      if (opt.dataset[prop] !== id && id !== '') {
        $(slave).val('');
      }
      $(slave).change();
    };
    if (master.hasMasterChange !== true) {
      master.addEventListener('change', changeSlave);
      master.changeSlave = changeSlave;
      master.hasMasterChange = true;
    }
  }
}

function colorTag(select) {
  var colorMe = (option) => {
    if (!option.id) {
      return option.text;
    }
    const color = option.element.dataset.color;
    const span = document.createElement(span);
    span.innerHTML = `<div class="tag is-rounded" style="background-color:${color};">&nbsp;&nbsp;</div> ${option.text}`
    return span;
  }
  initSelect(select, { templateResult: colorMe, templateSelection: colorMe});
}

function initSelect(select, options = { }) {
  if (select.hasBeenInited !== true) {
    if (options.width === undefined) { options.width = '100%'; }
    if (select.classList.contains('select-tags')) { options.tags = true; }
    if (select.getAttribute('hasBeenInited') === 'true') {
      select.parentNode.querySelector('.select2-container--default').remove();
    }
    if (!select.hasAttribute('originalOptions')) {
      select.setAttribute('originalOptions', select.innerHTML);
    }
    select.setAttribute('hasBeenInited', 'true');
    select.hasBeenInited = true;
    $(select).select2(options);
  }
  if (select.hasMasterChange === true && select.hasMC !== true) {
    $(select).on('change', select.changeSlave);
    select.hasMC = true;
  }
  if (select.masterLink !== undefined && select.hasML !== true) {
    $(select).on('change', select.masterLink);
    select.hasML = true;
  }
  if (select.selectLink !== undefined && select.hasSL !== true) {
    $(select).on('change', select.selectLink);
    $(select).on('select2:select', selectHandler);
    select.hasSL = true;
  }
}

function resetSelects() {
  const sels = document.querySelectorAll('select[data-reset="true"]');
  sels.forEach(select => {
    $(select).val(null).trigger('change');
    select.dataset.reset = false;
  });
}

export default function initNotification(el) {
  let timer = null;
  const notificon = document.getElementById('notificon');
  // if (!notificon) return;
  const notice = document.querySelector('.site-notice');
  if (autohide()) {
    enableAutoHide(notificon);
  }

  for (const notification of document.querySelectorAll(el)) {
    if (notification.closeEv === true) {
      continue;
    }

    notice.classList.remove('dismissed');

    notification.closeEv = true;
    const btnClose = notification.querySelector('button.close-notification');
    btnClose.addEventListener('click', ev => {
      notice.classList.add('dismissed');
    });
    if (autohide()) {
      timer = setTimeout(() => {
        // notificon.classList.add('warning-auto-hide');
        btnClose.dispatchEvent(new Event('click'));
      }, 3000);
    }
  }

  if (notificon.hasBeenInited !== true) {
    notificon.hasBeenInited = true;
    notificon.addEventListener('click', ec => {
      const notifications = document.querySelectorAll(el);
      if (autohide()) {
        if (notifications.length > 0) {
          notice.classList.remove('dismissed');
        }

        disableAutoHide(notificon);
        if (timer) {
          clearTimeout(timer);
        }
      } else {
        if (notifications.length > 0) {
          notice.classList.add('dismissed');

          // notificon.classList.add('warning-auto-hide');
        }

        enableAutoHide(notificon);
      }
    });
  }
}

function enableAutoHide(notificon) {
  notificon.classList.add('info-auto-hide');
  const icon = notificon.querySelector('div span i');
  icon.title = icon.dataset.text_when_autohide;
  localStorage.setItem('notification_auto_hide', true);
}

function disableAutoHide(notificon) {
  notificon.classList.remove('info-auto-hide');
  const icon = notificon.querySelector('div span i');
  icon.title = icon.dataset.text_normal;
  localStorage.setItem('notification_auto_hide', false);
}

function autohide() {
  return localStorage.getItem('notification_auto_hide', false) === 'true';
}

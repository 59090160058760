import { initDestroyNested } from './Form';
import selectInit from './Select2';

export default function Nests() {
  const nests = document.querySelectorAll('.nest-form');
  nests.forEach(nest => { initNest(nest); });
}

function initNest(nest) {
  const addNestBtn = nest.querySelector('.nest-add');
  if (addNestBtn && addNestBtn.hasBeenInited !== true) {
    addNestBtn.hasBeenInited = true;
    addNestBtn.addEventListener('click', () => { addNest(nest); });
  }
}

function addNest(nest) {
  const id = Date.now();
  const html = nest.querySelector('.nest-template').innerHTML
    .replaceAll('{{DELETE}}', id)
    .replaceAll('__DELETE__', id);
  const entries = nest.querySelector('.nest-entries');
  entries.insertAdjacentHTML('beforeend', html);
  const deleteCheckBox = nest.querySelectorAll(`input[type="checkbox"][name*="_destroy"][id*="${id}"]`);
  initDestroyNested(deleteCheckBox);
  selectInit(nest.querySelectorAll('.selectr'));
}
